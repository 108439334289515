<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ formTitle() }}</h4>
        </template>
        <b-container fluid>
          <b-row>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                    <b-row>
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.participant_id') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.garden_name') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenBtriService.participant_name') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.contact_no') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.email') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.status') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }} </b-th>
                                <b-th class="text-center" v-if="user_id === 1" width="17%">
                                  <b-form-checkbox
                                      id="checkbox-all"
                                      v-model="checkUncheckAll"
                                      name="checkbox-all"
                                  >
                                    {{ $t('globalTrans.checkAll') }}
                                  </b-form-checkbox>
                                </b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="beneficiary.details && beneficiary.details.length">
                                  <b-tr v-for="(item, index) in beneficiary.details" :key="index">
                                      <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                      <b-td class="text-center">{{ item.participant_id }}</b-td>
                                      <b-td class="text-center">{{ getGardenName(item.garden_id) }}</b-td>
                                      <b-td class="text-center">{{ currentLocale === 'en' ? item.participate_name_en : item.participate_name_bn }}</b-td>
                                      <b-td class="text-center">{{ item.contact_no | mobileNumber }}</b-td>
                                      <b-td class="text-center">{{ item.email }}</b-td>
                                      <b-td class="text-center">{{ getStatusType(item.status) }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn mr-1" size="sm" :title="$t('globalTrans.certificate')" @click="pdfExport(item.id)" class="action-btn edit"><i class="ri-printer-line"></i></b-button>
                                      </td>
                                      <td class="text-center" v-if="user_id === 1">
                                        <b-form-checkbox
                                            name="checked"
                                            v-model="item.is_certified"
                                            @change="checkUncheck"
                                            :value=1
                                            :unchecked-value=0
                                          >
                                          </b-form-checkbox>
                                      </td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="10" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                        <!-- -----------------Add More End--------------------- -->
                    </b-row>
                    <b-row class="text-right mb-3">
                        <b-col>
                          <b-button type="submit" @click="beneficiary.status = 3" variant="primary" class="mr-2">{{$t('globalTrans.save')}}</b-button>
                          <router-link :to="{ path: '/tea-garden-service/btri/certificate-issue'}" class="btn btn-danger btn-sm">
                              {{ $t('globalTrans.cancel') }}
                          </router-link>
                        </b-col>
                    </b-row>
                  </b-form>
                </b-overlay>
              </ValidationObserver>
          </b-row>
        </b-container>
      </card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { certificatePdf, getNominationInfo, getCertificate, issueCertificateStore } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['id'],
data () {
  return {
    user_id: 0,
    plantingMaterialList: [],
    valid: null,
    saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
    errors: [],
    loading: false,
    beneficiary: {
      status: 3,
      // user_id: 0,
      // applicant_name_en: '',
      // applicant_name_bn: '',
      // garden_id: 0,
      // service_id: 0,
      // total_qty: 0,
      // total_price: 0,
      details: []
    },
    details: {
      // garden_id: 0,
      // clone_price: 0,
      // service_id: 0,
      // planting_material_id: 0,
      // clone_name_id: 0
    },
    is_disable: false,
    isGardenAdmin: false,
    planting_material_error: '',
    clone_name_error: '',
    qty_error: '',
    editID: 0,
    checkUncheckAll: false
  }
},
created () {
  this.user_id = this.$store.state.Auth.authUser.user_id
  if (this.$route.params.id) {
    this.editID = this.$route.query.id
    this.getFormData()
  } else {
     this.$router.push({ name: 'tea_garden_service.btri.certificate_issue' })
  }

  if (this.isGardenAdminCheckGardenId()) {
    this.isGardenAdmin = true
    this.beneficiary.garden_id = this.isGardenAdminCheckGardenId()
  }
},
computed: {
  currentLocale () {
    return this.$i18n.locale
  },
  masterTeaGardenGenInfoList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
  },
  statusList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Nominated' : 'মনোনীত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Participated' : 'অংশগ্রহনকৃত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Certificate Issued' : 'সার্টিফিকেট ইস্যু' }
      ]
      return list
  }
},
watch: {
  checkUncheckAll: function (newValue) {
    if (newValue) {
      this.beneficiary.details.map(item => {
        item.is_certified = 1
      })
    } else {
      this.beneficiary.details.map(item => {
        item.is_certified = 0
      })
    }
  }
},
methods: {
  async getFormData () {
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.getData(teaGardenServiceBaseUrl, `${getNominationInfo}/${this.$route.params.id}`)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.beneficiary.details = result.data
    } else {
      this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: result.message
      })
      this.$router.push({ path: '/tea-garden-service/btri/participate-list' })
    }
    this.loading = false
  },
  getStatusType (sta) {
      const reqStatusObj = this.statusList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
  },
  issueCertificate (id) {
      window.vm.$swal({
        title: window.vm.$t('teaGardenBtriService.issue_certificate'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          const params = {
            status: 3
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(teaGardenServiceBaseUrl, `${getCertificate}/${id}`, params).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: window.vm.$t('globalTrans.success'),
                message: window.vm.$t('globalTrans.update_msg'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        }
      })
    },
    async saveUpdate () {
    if (!this.beneficiary.details.length) {
      return this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: 'Please add at least one participant!!!'
      })
    }
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.postData(teaGardenServiceBaseUrl, issueCertificateStore, this.beneficiary)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/certificate-issue' })
    } else {
      this.$toast.error({
        title: this.$t('globalTrans.error'),
        message: result.message
      })
      this.$router.push({ path: '/tea-garden-service/btri/certificate-issue' })
    }
    this.loading = false
  },
  getGardenName (id) {
    const data = this.masterTeaGardenGenInfoList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
},
  formTitle () {
      return this.$t('teaGardenBtriService.issue_certificate')
  },
  async pdfExport (ids) {
      const params = Object.assign({ id: ids, request_type: 'pdf', local: this.$i18n.locale, status: this.beneficiary.status })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 23)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, certificatePdf, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
